document.addEventListener('DOMContentLoaded', () => {
    const selectList = document.querySelectorAll('.select')
    if(selectList.length) {
        selectList.forEach((select) => {
            select.addEventListener('click', (evt) => {
                evt.stopPropagation()
                if(!evt.target.closest('.select__list-box')) {
                    select.classList.toggle('select--active')
                }
            })
        })
    }

    document.addEventListener('click', closeAllSelect)

    function closeAllSelect() {
        selectList.forEach((select) => {
            select.classList.remove('select--active')
        })
    }
})