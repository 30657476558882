document.addEventListener('DOMContentLoaded', () => {
  const accordionBlockList = document.querySelectorAll('.accordion__block');

  if (accordionBlockList.length) {
    accordionBlockList.forEach((block) => {
      block.querySelector('.accordion__header').addEventListener('click', () => {
        block.classList.toggle('accordion__block--active');
      });
    });
  }
});
